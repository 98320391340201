<template>
  <div class="main">
    <div class="banner">
      <!-- <img src="../../assets/image/banner_index.png" alt=""> -->
      <!-- autoplay -->
      <Carousel autoplay v-model="banner_value">
        <Carousel-item>
          <div class="demo-carousel">
            <div class="demo-carousel-item">
              <div class="carousel_content">
                <h2>众博商城系统</h2>
                <p>全场景电商解决方案助企业突破营销壁垒</p>
                <button>立即咨询</button>
              </div>
              <img class="demo-img" src="../../assets/image/banner_index1.png">
            </div>
          </div>
        </Carousel-item>
        <Carousel-item>
          <div class="demo-carousel">
            <div class="demo-carousel-item">
              <div class="carousel_content">
                <h2>众博商城系统</h2>
                <p>全场景电商解决方案助企业突破营销壁垒</p>
                <button>立即咨询</button>
              </div>
              <img class="demo-img" src="../../assets/image/banner_index1.png">
            </div>
          </div>
        </Carousel-item>
        <Carousel-item>
          <div class="demo-carousel">
            <div class="demo-carousel-item">
              <div class="carousel_content">
                <h2>众博商城系统</h2>
                <p>全场景电商解决方案助企业突破营销壁垒</p>
                <button>立即咨询</button>
              </div>
              <img class="demo-img" src="../../assets/image/banner_index1.png">
            </div>
          </div>
        </Carousel-item>
     
      </Carousel>
    </div>
    <div class="pattern">
      <div class="pattern_con">
        <h3>全场景全链路产品服务助力企业数字升级</h3>
        <div class="pattern_con_bg">
          <img
            src="../../assets/image/pattern_index.png"
            alt=""
          >
        </div>
        <div class="pattern_box">
          <div class="pattern_list_top">
            <div class="pattern_member">
              <h4>会员/分销商</h4>
              <div class="pattern_member_list">
                <div class="pattern_member_con">
                  <i></i>
                  <span>个人中心</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>推荐分佣</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>会员权益</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>分销推广</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>充值提现</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>独立微店</span>
                </div>
              </div>
            </div>
            <div class="pattern_member">
              <h4>终端访问</h4>
              <div class="pattern_member_list">
                <div class="pattern_member_con">
                  <i></i>
                  <span>小程序</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>消费者端</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>微商城</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>商户端</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>PC端</span>
                </div>
              </div>
            </div>
          </div>
          <div class="pattern_list_bottom">
            <div class="pattern_member">
              <h4>终端管理</h4>
              <div class="pattern_member_list">
                <div class="pattern_member_con">
                  <i></i>
                  <span>商品管理</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>会员管理</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>订单管理</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>数据管理</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>活动管理</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>财务管理</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>接口管理</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>平台装修</span>
                </div>
              </div>
            </div>
            <div class="pattern_member">
              <h4>入驻商</h4>
              <div class="pattern_member_list">
                <div class="pattern_member_con">
                  <i></i>
                  <span>提供货源</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>店铺商品</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>佣金结算</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>商家入驻</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>批发平台</span>
                </div>
                <div class="pattern_member_con">
                  <i></i>
                  <span>店铺装修 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="retailers_wrap">
      <div class="retailers">
        <div class="retailers_header">
          <h3>电商方案</h3>
          <div class="retailers_header_nav">
            <ul>
              <li
                @click='change(1)'
                :class='currentIndex==1?"active":""'
              ><span>新零售云店</span></li>
              <li
                @click='change(2)'
                :class='currentIndex==2?"active":""'
              ><span>直播带货</span></li>
              <li
                @click='change(3)'
                :class='currentIndex==3?"active":""'
              ><span>社区团购</span></li>
              <li
                @click='change(4)'
                :class='currentIndex==4?"active":""'
              ><span>代理分销</span></li>
              <li
                @click='change(5)'
                :class='currentIndex==5?"active":""'
              ><span>微商城</span></li>
              <li
                @click='change(6)'
                :class='currentIndex==6?"active":""'
              ><span>营销网站</span></li>
            </ul>
          </div>
        </div>
        <div
          class="retailers_con"
          v-show="currentIndex==1"
        >
          <div class="retailers_con_left">
            <h3>新零售云店</h3>
            <p>新零售云店，是众博推出的面向品牌连锁企业的全渠道零售解决方案，致力于线下线上业务融合。其中包含商品在线化，门店员工在线导购化，营销推广社交化，订单履约智能化，会员信息及销售管理等数据全面数字化。</p>
            <div class="retailers_con_left_btn">
              <button @click="changePage('/retail')">查看详情</button>
              <button @click="consulting()">立即咨询</button>
            </div>
          </div>
          <div class="retailers_con_right">
            <img
              src="../../assets/image/index_retailers1.png"
              alt=""
            >
          </div>
        </div>
        <div
          class="retailers_con"
          v-show="currentIndex==2"
        >
          <div class="retailers_con_left">
            <h3>直播带货</h3>
            <p>众博直播卖货系统是基于微信直播小程序研发，借助直播先天优势，通过现场互动、实物演示、在线答疑，增强顾客信任，加速购买决策。打通直播平台，轻松实现边播边卖，提升购买转化。应对大流量系统更加稳定，播放流畅自如，带来优质的直播购物体验</p>
            <div class="retailers_con_left_btn">
              <button @click="changePage('/broadcast')">查看详情</button>
              <button @click="consulting()">立即咨询</button>
            </div>
          </div>
          <div class="retailers_con_right">
            <img
              src="../../assets/image/index_retailers2.png"
              alt=""
            >
          </div>
        </div>
        <div
          class="retailers_con"
          v-show="currentIndex==3"
        >
          <div class="retailers_con_left">
            <h3>社区团购</h3>
            <p>社区团购是一种全新的团购模式，平台统一提供相应产品、通过小区团长、辐射小区用户。社区用户在小程序下单后、平台可直接发货至用户，或用户至团长处自提。平台通过【预售+拼团】，拼团以销定产，降低损耗和成本、用户可得到真正的实惠。</p>
            <div class="retailers_con_left_btn">
              <button @click="changePage('/community')">查看详情</button>
              <button @click="consulting()">立即咨询</button>
            </div>
          </div>
          <div class="retailers_con_right">
            <img
              src="../../assets/image/index_retailers3.png"
              alt=""
            >
          </div>
        </div>
        <div
          class="retailers_con"
          v-show="currentIndex==4"
        >
          <div class="retailers_con_left">
            <h3>代理分销</h3>
            <p>线上移动商城+线下体验店+社交裂变分销，让线上线下融汇一体，赋能门店，重构消 费连接，助力企业快速搭建社交新零售平台。</p>
            <div class="retailers_con_left_btn">
              <button @click="changePage('/agent')">查看详情</button>
              <button @click="consulting()">立即咨询</button>
            </div>
          </div>
          <div class="retailers_con_right">
            <img
              src="../../assets/image/index_retailers4.png"
              alt=""
            >
          </div>
        </div>
        <div
          class="retailers_con"
          v-show="currentIndex==5"
        >
          <div class="retailers_con_left">
            <h3>微商城</h3>
            <p>通过微信自带流量，然后在技术领域加以开发，与微信相互结合，产生众多的特色优势，让您的商城在微信这个移动市场更加轻松快速的赚钱</p>
            <div class="retailers_con_left_btn">
              <button @click="changePage('/applet')">查看详情</button>
              <button @click="consulting()">立即咨询</button>
            </div>
          </div>
          <div class="retailers_con_right">
            <img
              src="../../assets/image/index_retailers5.png"
              alt=""
            >
          </div>
        </div>
        <div
          class="retailers_con"
          v-show="currentIndex==6"
        >
          <div class="retailers_con_left">
            <h3>营销官网</h3>
            <p>通过新零售平台赋能与创新营销场景的再升级，推动品牌总部与B端经销商（网批商）建立起全新的商业运维关系，共同为C端用户带来更便捷、高质量的服务体验。</p>
            <div class="retailers_con_left_btn">
              <button @click="changePage('/marketing')">查看详情</button>
              <button @click="consulting()">立即咨询</button>
            </div>
          </div>
          <div class="retailers_con_right">
            <img
              src="../../assets/image/index_retailers6.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <h3>系统高性能技术架构，保障企业安全稳定</h3>
      <div class="advantage_list">
        <div class="advantage_list_left">
          <h4><span>系统</span>优势</h4>
          <div class="advantage_list_left_con">
            <h5>高扩展</h5>
            <p>商城系统标准的API接口，前后端分离，支持多端开发</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>网站架构</h5>
            <p>支持负载均衡，可部署Redis、Memcache等缓存机制</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>软件结构</h5>
            <p>程序、模版、数据库三者独立，灵活扩展</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>高性能</h5>
            <p>系统支持数据库集群部署，页面缓存、性能优越</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>低耦合性</h5>
            <p>每个功能模块之间通过API接口实现数据关联，减少绑定关系</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>可追溯性</h5>
            <p>每个开发的环节都有详细的版本记录</p>
          </div>
          <div class="advantage_list_left_btn">
            <button>立即咨询</button>
          </div>
        </div>
        <div class="advantage_list_right">
          <h4><span>技术</span>优势</h4>
          <div class="advantage_list_left_con">
            <h5>模版分离</h5>
            <p>支持多套模版并存，采用与程序彻底分离的模版分析引擎</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>URL重写技术</h5>
            <p>采用URL重写技术，使搜索引擎更容易收录页面</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>移动架构</h5>
            <p>基于Uniapp模式，小程序+APP+微商城一体化开发</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>权限管控</h5>
            <p>可控路由级数据权限控制，后台控制不同管理员权限操作范围</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>开发规范</h5>
            <p>PHP、Laravel编程开发规范，主流商城系统标准</p>
          </div>
          <div class="advantage_list_left_con">
            <h5>静态化分离</h5>
            <p>支持OSS、OBS、COS等文件存储，不占用系统服务器资源</p>
          </div>
          <div class="advantage_list_left_btn">
            <button>立即咨询</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      currentIndex: 1,
      banner_value: 0
    };
  },
  methods: {
    change: function(index) {
      this.currentIndex = index;
    },
    changePage: function(val) {
      this.$router.push(val);
    },
    consulting: function() {
      window.location.href =
        "http://wpa.qq.com/msgrd?v=3&uin=2852837044&site=qq&menu=yes";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.main {
  width: 100%;
}
.banner {
  width: 100%;
  height: 540px;
}
.banner .ivu-carousel {
  width: 100%;
  height: 100%;
}
.banner .demo-carousel {
  width: 100%;
  height: 540px;
  background: url(../../assets/image/banner_bg.png);
}
.banner .demo-carousel-item {
  width: 1200px;
  height: 540px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.carousel_content {
  text-align: left;
}
.banner .demo-carousel-item h2 {
  font-size: 50px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 84px;
  margin-bottom: 10px;
}
.banner .demo-carousel-item p {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ffffff;
  line-height: 33px;
  margin-bottom: 40px;
}
.banner .demo-carousel-item button {
  width: 185px;
  height: 55px;
  background: #ffffff;
  border-radius: 2px;
  border: none;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ff0610;
  line-height: 55px;
}
.banner .demo-img {
  width: 668px;
  height: auto;
}
.pattern {
  position: relative;
  max-width: 1200px;
  padding-bottom: 20px;
  margin: auto;
}
.pattern_con h3 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  padding: 70px 0 30px 0;
}
.pattern_con_bg {
  width: 740px;
}
.pattern_con_bg img {
  width: 740px;
  position: absolute;
  left: 50%;
  margin-left: -370px;
  height: 500px;
}
.pattern_list_top,
.pattern_list_bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.pattern_list_bottom {
  margin-bottom: 90px;
}
.pattern_member {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #fdc9cc;
  padding: 20px;
  box-sizing: border-box;
  width: 260px;
  z-index: 2;
}
.pattern_member_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.pattern_member h4 {
  text-align: left;
}
.pattern_member_con {
  text-align: left;
  width: 50%;
  margin-top: 6px;
}
.pattern_member_con i {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #fdc9cc;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  margin-right: 4px;
}
.pattern_member_con span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
.retailers_wrap {
  width: 100%;
  height: 581px;
  background: #f0f4fc;
  box-sizing: border-box;
  padding: 40px 0 35px 0;
}
.retailers {
  max-width: 1200px;
  margin: auto;
}
.retailers_header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d6e4ff;
}
.retailers_header h3 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.retailers_header_nav ul {
  display: flex;
}
.retailers_header_nav ul li {
  padding: 0 15px;
  height: 80px;
  line-height: 80px;
  cursor: pointer;
  font-size: 16px;
}
.retailers_con {
  background: #fff;
  padding: 30px 25px 30px 50px;
  box-sizing: border-box;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.retailers_con_right {
  width: 473px;
  height: 353px;
  /* border: 2px solid #D6E4FF; */
  margin-left: 48px;
}
.retailers_con_right img {
  width: 100%;
  height: 100%;
}
.retailers_con_left {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 40px 0 60px 0;
}
.retailers_con_left h3 {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.retailers_con_left p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 20px;
}
.retailers_con_left_btn button {
  width: 130px;
  height: 44px;
  border-radius: 2px;
  outline: 0;
  border: 0;
  cursor: pointer;
}
.retailers_con_left_btn button:hover {
  box-shadow: 0px 2px 7px 4px rgba(231, 231, 231, 0.8);
}
.retailers_con_left_btn button:nth-child(1) {
  background: #ffffff;
  border: 1px solid #9b9b9b;
  color: #4a4a4a;
  margin-right: 20px;
}
.retailers_con_left_btn button:nth-child(2) {
  background: #ff0610;
  /* border: 1px solid #9B9B9B; */
  color: #fff;
}
.advantage {
  max-width: 1200px;
  margin: auto;
}
.advantage h3 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  padding: 35px 0;
}
.advantage_list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
}
.advantage_list_left,
.advantage_list_right {
  width: 585px;
  height: 638px;
  background: #ffffff;
  box-shadow: 0px 4px 18px 0px #f0f4fc;
  border-radius: 2px;
  border: 1px solid #f0f4fc;
  box-sizing: border-box;
  /* padding-left:85px; */
  position: relative;
}
.advantage_list_left h4,
.advantage_list_right h4 {
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 800;
  color: #000000;
  padding: 25px 0 30px 0;
}
.advantage_list_left h4 span,
.advantage_list_right h4 span {
  border-bottom: 3px solid #ff0610;
  padding-bottom: 2px;
}
.advantage_list_left_con h5 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 800;
  color: #000000;
  text-align: left;
  margin-left: 85px;
}
.advantage_list_left_con p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  text-align: left;
  margin: 10px 0 25px 0;
  margin-left: 85px;
}
.advantage_list_left_btn button {
  width: 130px;
  height: 44px;
  background: #ff0610;
  border-radius: 2px;
  border: 1px solid #ff0610;
  color: #fff;
  position: absolute;
  left: 50%;
  bottom: 35px;
  margin-left: -65px;
  cursor: pointer;
}
.advantage_list_left_btn button:hover {
  box-shadow: 0px 2px 7px 4px rgba(231, 231, 231, 0.8);
}
.active {
  border-bottom: 3px solid red;
  color: #ff0610;
}
</style>
